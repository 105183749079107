// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getLeadMode,
  getUserDetails,
  handleTokenError,
  isLead,
  isTeacher,
} from "../../../components/src/NativeWebRouteWrapper/Utils";
import { ChangeEvent } from "react";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
}

type Color = "success" | "info" | "warning" | "error" | undefined;
interface StandardSets {
  card1: boolean;
  card2: boolean;
  card3: boolean;
  card4: boolean;
}
interface SchoolData {
  data: {
    id: string;
    type: string;
    attributes: {
      name: string;
      address: string;
      admin_user_id: number;
      school_image: {
        url: string|null;
      };
      school_credits?: {
        data: CreditData[];
      };
      manager_details?: {
        data: ManagerData;
      };
    };
  };
}
interface Standard {
  id: number;
  title: string;
  grade: string[];
  subject_tags: string[];
  standard_set_id: number;
  description: string;
  status: string;
  external_url: string;
  created_at: string;
  updated_at: string;
}
interface Logo {
  url: string;
}

interface Attributes {
  id: number;
  title: string;
  status: string;
  account_added: {account_standard_id:string}|null;
  standards_count: number;
  logo: Logo;
}

interface StandardData{
  id:string;
  type:string;
  attributes: Attributes;
}


interface SchoolObject {
  name:string;
  credits:string;
  district:string;
  state:string;
  address:string;
}
interface CreditData {
  id: string;
  type: string;
  attributes: {
    recieved: number;
    award: number;
    reward: number;
    claimed: number;
    reclaimed: number;
    spent: number;
    current_balance: number;
    entity_id: number;
    entity_type: string;
    unused_earned_credits: number;
    unused_allocated_credits: number;
  };
}

interface ManagerData {
  id: string;
  type: string;
  attributes: {
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    betabox_image: string | null;
    project_creator_card_image: string | null;
  };
}

interface S {
  // Customizable Area Start
  standardSets:StandardSets
  imageUrl: string | null;
  userSchoolInfo:SchoolData|null; 
  schoolId:string |undefined;
  loading:boolean;
  schoolObject: SchoolObject;
  standardData:StandardData[];
  standardCount:number;
  // Customizable Area End
}

interface SS {
  id: any;
}
export default class LeadSchoolController extends BlockComponent<Props, S, SS> {
  inputRef: any;
  // Customizable Area Start
  updateSchoolDataApiId:string;
  getSchoolDataApiId: string = "";
  getStandardsDataApiId:string="";
  selectStandardApiCallId:string="";
  unSelectStandardApiCallId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.updateSchoolDataApiId = ""
    this.state = {
     standardSets:{
      card1:false,
      card2:false,card3:false,card4:false,
     },
     imageUrl:"",
     userSchoolInfo:{
       data: {
         id: "",
         type: "",
         attributes: {
           name: "",
           address: "",
           admin_user_id: 0,
           school_image: {
             url: null
           },
           school_credits: undefined,
           manager_details: undefined
         }
       }
     },
     schoolId:"",
     loading:false,
     schoolObject:{
      name:"",
      credits:"",
      district:"",
      state:"",
      address:""
     },
     standardData:[],
     standardCount:0,
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    const userData = localStorage.getItem('userInfo') || "";
    const userSchoolDataObj=JSON.parse(userData);
    this.setState({userSchoolInfo:userSchoolDataObj.school,imageUrl:userSchoolDataObj.school.data.attributes.school_image?.url});
    this.getSchoolData(userSchoolDataObj.id);
    this.getStandardsData();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson);
      if(responseJson)
        {
          this.setState({
            loading: false,
          });
        }
    if (apiRequestCallId === this.updateSchoolDataApiId) {
      if(responseJson.data){
      this.updateDataToLocalStorage(responseJson)
      }
    }
    if (apiRequestCallId === this.getSchoolDataApiId) {
      this.setState({
        schoolObject: {
          name: responseJson.data.attributes.school.data.attributes.name,
          credits:
            responseJson.data.attributes.school.data.attributes.school_credits.data[0].attributes
              .current_balance,
          district:
            responseJson.data.attributes.school.data.attributes.district?.name || "-",
          state: responseJson.data.attributes.school.data.attributes.state?.name || "-",
          address: responseJson.data.attributes.school.data.attributes.address,
        },
      });
    }
    if(apiRequestCallId === this.getStandardsDataApiId)
      {
        this.setState({standardData:responseJson.data,standardCount:responseJson.meta.pagination.total_count})
      }
      if(apiRequestCallId === this.unSelectStandardApiCallId || apiRequestCallId === this.selectStandardApiCallId )
        {
          this.getStandardsData()
        }
   
    
  }
    // Customizable Area End
  }
  // Customizable Area Start
  handleChange = (id: string, account_added: {account_standard_id:string}|null) => {
    if (account_added) {
      this.unSetStandard(account_added.account_standard_id);
     
    } else {
      this.selectStandard(id);
  
    }
  };
   updateDataToLocalStorage=(responseJson: { data: { attributes: { school_image: { url: any; }; }; }; })=>{
    let getData = JSON.parse(localStorage.getItem("userInfo") || "");
    this.setState({imageUrl:responseJson?.data?.attributes?.school_image.url,loading:false})
    localStorage.setItem(
      "userInfo",
      JSON.stringify({
        ...getData,
        school: {
          ...getData.school.data,
          data: {
            ...getData.school.data, attributes: {
              ...getData.school.data.attributes, school_image: {
                url:responseJson.data.attributes.school_image.url
              }
            }
          },
        },
      })
    );
   
    window.dispatchEvent(new Event("storage"));
   }
  onImageChangeVendor = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {    
      const file = event.target.files[0]; 
     var data = new FormData();
     data.append("school_image", file, "[PROXY]");
     this.updateSchoolProfile(data);
     this.setState({loading:true})
    }
    
  };

  handleDivClick = () => {
    if (this.inputRef.current) {
      this.inputRef.current.click();
    }
  };
  getStatus = (item: {}|null) => {
    return item ? true : false;
  };

  updateSchoolProfile = (data:FormData) => {
    const header = {
      token:getAuthToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateSchoolDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.schoolPatchApi}${this.state?.userSchoolInfo?.data?.id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),data
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getSchoolData = (id:string) => {
    const header = {
      token:getAuthToken()
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSchoolDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getApiEndPoint}${id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    {
      this.setState({
        loading: true,
      });
    }
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getStandardsData = () => {
    const header = {
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getStandardsDataApiId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getStandardsDataApiEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  selectStandard = (standardID: string | number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const httpBody = {
      data: {
        attributes: {
          standard_set_id: +standardID,
        },
      },
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.selectStandardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.setStandardsDataApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  unSetStandard = (standardID: string | number) => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: getAuthToken(),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unSelectStandardApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unSetStandardsDataApiEndpoint + standardID
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteMethod
    );

    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  // Customizable Area End
}

// Customizable Area End
