import {FormHelperText} from "@material-ui/core";
import React from "react";
import { getUserDetails } from "./NativeWebRouteWrapper/Utils";
export const renderFormErrorHelperText = (error:string|undefined, touched:boolean|undefined) => {
    const errors= Boolean(error) && touched;
    let helpertext = undefined;
    if (error && touched) {
     helpertext = <FormHelperText error={true}>{error}</FormHelperText>;
    }
   return {
    error:errors,
    helpertext:helpertext,
   } 
  };

export const getParameterProject = (params: string, getID: string) => {
  let getIDx;
  if (/^\d+$/.test(params)) {
    getIDx = params;
  } else {
    getIDx = getID;
  }
  return getIDx;
};

export const openEmailClient = () => {
  let userInfo = getUserDetails()
  let userName = userInfo.first_name.concat(" ", userInfo.last_name);
    const subject = "Connect My Classbox Account To My School";
    const body = `Hello Classbox!\n\nI would like to request that my Classbox account be linked to my school’s account. By submitting this request, I am confirming that I am actively employed at the school with which I wish to connect my account.\n\nSchool Name: [enter here]\nSchool District: [enter here]\nState: [enter here]\n\n${userName}`;
    const mailtoLink = `mailto:support@classbox.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
};
export const validateLocalPartLength = (value:string) => {
  if (!value) return false; 
  const [localPart] = value.split('@');
  return localPart.length >= 1 && localPart.length <= 64;
};
export const validateDomainPartLength = (value: string) => {
  if (!value) return false; 
  const [, domain] = value.split('@'); 
  return domain !== undefined && domain.length >= 2 && domain.length <= 255;
};