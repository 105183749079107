// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  getAuthToken,
  getUserDetails,
  handleTokenError,
} from "../../../components/src/NativeWebRouteWrapper/Utils";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}
export interface FormValues{
  procurementContactName:string,
  procurementContactEmail:string,
  requesterContact:string,
  requesterEmail:string,
}
type Color = "success" | "info" | "warning" | "error" | undefined;
interface S {
  // Customizable Area Start
  open: boolean;
  historyList: any;
  loading: boolean;
  successManager: string;
  addData: CreditQuote[];
  managerDetails: any;
  page: number;
  pagination: any;
  schoolCreditHistory: any;
  ismodalOpen:boolean;
  credits:number;
  price:number;
  formValues:FormValues;
  showSnackbar:boolean;
  severity:Color;
  // Customizable Area End
}
export interface CreditQuote {
  id: string;
  type: string; 
  attributes: {
    credits: number;
    price: number;
    discount: number;
    quote: Quote; 
  };
}

interface Quote {
  url: string;
}


interface SS {
  id: any;
}
// Customizable Area Start
export default class AddCreditsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  submitApiCallId: any;
  historyDetails: any;
  creditDetails:string="";
  updateQuoateRequestApiId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      open: false,
      historyList: [],
      managerDetails: [],
      loading: false,
      successManager: "",
      ismodalOpen:false,
      showSnackbar:false,
      formValues:{
        procurementContactName: '',
        procurementContactEmail: '',
        requesterContact: '',
        requesterEmail: '',
      },
      severity:undefined,
      addData: [],
      page: 1,
      pagination: {},
      schoolCreditHistory: [],
      credits: 0,
      price: 0,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.getHistoryDetails();
    this.getCreditDetails();
    // Customizable Area End
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      handleTokenError(responseJson)
      if (responseJson) {
        this.setState({
          loading: false,
        });
      }

      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.historyDetails) {
          this.setState({
            historyList: responseJson.history,
            managerDetails: responseJson.manager_details,
            pagination: responseJson.meta.pagination,
            schoolCreditHistory: [
              ...this.state.schoolCreditHistory,
              ...responseJson.history,
            ],
          });
        }
        if(apiRequestCallId === this.updateQuoateRequestApiId)
          {
            this.setState({
             ismodalOpen:false,
              loading:false
            });
            this.setState({
              showSnackbar: true,
              severity: "success",
            })
            
          }
          if(apiRequestCallId === this.creditDetails)
            {
              this.setState({
               addData:responseJson.data
              });       
            }
      }
    }
    // Customizable Area End
  }
  getHistoryDetails = () => {
    const token = getAuthToken();
    const userInfo = getUserDetails();
    const id = userInfo.school.data.id;
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.historyDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.historyDetails}?school_id=${id}&page=${this.state.page
      }&per_page=${5}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getCreditDetails = () => {
    const token = getAuthToken();
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.creditDetails = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.creditQuotes
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  updateQuoateRequest(data:FormData) 
  {
    let token = getAuthToken();
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateQuoateRequestApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateQuatoeEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      data
    );
    this.setState({
      loading: true,
    });

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }
  handleDownload = (url: any) => {
    window.open(url);
  };
    handleQuoteData=(values:FormValues,credit:number)=>
    { 
      const creditDeatils= credit.toString()+ "Credit Package";
      const formData = new FormData();
      formData.append('credit_package_name', creditDeatils);
      formData.append('procurement_email', values.procurementContactEmail);
      formData.append('procurement_name', values.procurementContactName);
      this.updateQuoateRequest(formData);
    };
  openManagerMail = () => {
    window.location.href = `mailto:${getUserDetails().school.data.attributes.manager_details.data.attributes
        .email
      }?subject=SendMail&body=Description`;
  };

  fetchMoreData = () => {
    this.setState(
      {
        page: this.state.page + 1,
      },
      () => this.getHistoryDetails()
    );
  };
  handleModal=()=>{
    this.setState({ismodalOpen:false});
  }
  handlModalOpen=(price:number,credits:number)=>{
    this.setState({ismodalOpen:true,price:price,credits:credits});
  }
  handleCloseSnackbar=()=>{
   this.setState({showSnackbar:false});
  }
}

// Customizable Area End

// Customizable Area End
