import React, { useEffect, useState } from "react";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  Button,
  FormHelperText,
} from "@material-ui/core";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  getUserDetails,
  isTeacher,
} from "../../../../components/src/NativeWebRouteWrapper/Utils";

const GeneralProfileForm = ({ state, handleSubmit }: any) => {
  let userData = getUserDetails()
  let userProfileSchema = Yup.object().shape({
    prefix: Yup.string().required("Field is Required."),
    firstName: Yup.string().required("Field is Required."),
    lastName: Yup.string().required("Field is Required."),
    role: Yup.string().nullable().required("Field is Requires."),
    gradeTaught:
      getUserDetails()?.role?.id === 4
        ? Yup.string().required("Field is Required.")
        : Yup.string(),
  });

  const [IsTeacher, setIsTeacher] = useState(() => isTeacher());

  useEffect(() => {
    const handleStorage = () => {
      setIsTeacher(isTeacher());
    };
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  return (
    <Formik
      initialValues={state.initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
      validationSchema={userProfileSchema}
    >
      {(props) => (
        <form
          style={{ width: "100%" }}
          onSubmit={props.handleSubmit}
          className="general-profile-form"
        >
          <Grid sm={12} className="generel-detail-contaner" item container>
            <Grid item sm={12} className="title-container">
              <span className="general-title">General</span>
            </Grid>
            <Grid md={1} sm={2} xs={2} item className="input-container">
              <InputLabel htmlFor="prefix" className="input-label">
                Prefix
              </InputLabel>
              <Select
                className="input-field"
                id="outlined-basic"
                variant="outlined"
                name="prefix"
                type="select"
                placeholder="Prefix"
                onChange={props.handleChange}
                value={userData.prefix || ""}
                style={{minWidth: "78px"}}
                displayEmpty
                data-test-id = "selectPrefix"
                //@ts-ignore
              >
                <MenuItem value="Ms">Ms.</MenuItem>
                <MenuItem value="Mrs">Mrs.</MenuItem>
                <MenuItem value="Mr">Mr.</MenuItem>
                <MenuItem value="Dr">Dr.</MenuItem>
              </Select>
              {props.errors.prefix && props.touched.prefix && (
                <FormHelperText error={true}>
                  {props.errors.prefix}
                </FormHelperText>
              )}
            </Grid>
            <Grid sm={11} item container spacing={4}>
              <Grid item sm={4} className="input-container">
                <InputLabel htmlFor="firstName" className="input-label">
                  Name
                </InputLabel>
                <TextField
                  data-test-id="first-name"
                  className="input-field"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="firstName"
                  type="text"
                  placeholder="FirstName"
                  onChange={props.handleChange}
                  value={userData.first_name}
                />
                {props.errors.firstName && props.touched.firstName && (
                  <FormHelperText error={true}>
                    {props.errors.firstName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={4} className="input-container">
                <InputLabel htmlFor="lastName" className="input-label">
                  Last Name
                </InputLabel>
                <TextField
                  className="input-field"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="lastName"
                  type="text"
                  placeholder="LastName"
                  onChange={props.handleChange}
                  value={userData.last_name}
                />
                {props.errors.lastName && props.touched.lastName && (
                  <FormHelperText error={true}>
                    {props.errors.lastName}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item sm={4} className="input-container">
                <InputLabel htmlFor="email" className="input-label">
                  Email
                </InputLabel>
                <TextField
                  className="input-field"
                  id="outlined-basic"
                  variant="outlined"
                  size="small"
                  name="email"
                  type="text"
                  disabled
                  placeholder="Email"
                  onChange={props.handleChange}
                  value={userData.email}
                />
                {props.errors.email && props.touched.email && (
                  <FormHelperText error={true}>
                    {props.errors.email}
                  </FormHelperText>
                )}
              </Grid>
            </Grid>
            <Grid item sm={6} className="input-container">
              <InputLabel htmlFor="role" className="input-label">
                Role
              </InputLabel>
              <Select
                className="input-field"
                id="outlined-basic"
                variant="outlined"
                name="role"
                type="select"
                placeholder="Pick ROle"
                onChange={props.handleChange}
                value={userData.teacher_type || ""}
                displayEmpty
                //@ts-ignore
              >
                <MenuItem value="administrator">Administrator</MenuItem>
                <MenuItem value="stem_teacher">STEM Teacher</MenuItem>
                <MenuItem value="cte_teacher">CTE Teacher</MenuItem>
                <MenuItem value="general_studies_teacher">
                  General Studies Teacher
                </MenuItem>
              </Select>
              {props.errors.role && props.touched.role && (
                <FormHelperText error={true}>
                  {props.errors.role}
                </FormHelperText>
              )}
            </Grid>
            {((props.values.role !== "administrator" &&
              getUserDetails()?.role?.id !== 4) ||
              getUserDetails()?.role?.id === 4) && (
              <Grid item sm={6} className="input-container">
                <InputLabel htmlFor="gradeTaught" className="input-label">
                  Grade Taught
                </InputLabel>
                <Select
                  className="input-field"
                  id="outlined-basic"
                  variant="outlined"
                  name="gradeTaught"
                  type="select"
                  onChange={props.handleChange}
                  value={userData.grade || ""}
                  displayEmpty
                  //@ts-ignore
                >
                  <MenuItem value="1st">1st</MenuItem>
                  <MenuItem value="2nd">2nd</MenuItem>
                  <MenuItem value="3rd">3rd</MenuItem>
                  <MenuItem value="4th">4th</MenuItem>
                  <MenuItem value="5th">5th</MenuItem>
                  <MenuItem value="6th">6th</MenuItem>
                  <MenuItem value="7th">7th</MenuItem>
                  <MenuItem value="8th">8th</MenuItem>
                  <MenuItem value="9th">9th</MenuItem>
                  <MenuItem value="10th">10th</MenuItem>
                  <MenuItem value="11th">11th</MenuItem>
                  <MenuItem value="12th">12th</MenuItem>
                </Select>
                {props.errors.gradeTaught && props.touched.gradeTaught && (
                  <FormHelperText error={true}>
                    {props.errors.gradeTaught}
                  </FormHelperText>
                )}
              </Grid>
            )}
            <Grid item sm={12}>
              <Button type="submit" className="submit-btn">
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

export default GeneralProfileForm;
