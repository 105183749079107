// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import { Redirect } from 'react-router-dom';
import {
  isUserloggedIn,
  showHeader
} from '../../components/src/NativeWebRouteWrapper/Utils';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import ProjectReporting from '../../blocks/ProjectReporting/src/ProjectReporting';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Ordermanagement from '../../blocks/ordermanagement/src/OrderManagement';
import OrderDetails from '../../blocks/ordermanagement/src/OrderDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import BreadcrumbNavigation from '../../blocks/BreadcrumbNavigation/src/BreadcrumbNavigation';
import ShoppingCartOrders from '../../blocks/shoppingcart/src/ShoppingCartOrders';
import AddShoppingCartOrderItem from '../../blocks/shoppingcart/src/AddShoppingCartOrderItem';
import ProjectTemplates from '../../blocks/ProjectTemplates/src/ProjectTemplates';
import Download from '../../blocks/Download/src/Download';
import SplitViewInterface from '../../blocks/SplitViewInterface/src/SplitViewInterface';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Videos from '../../blocks/videos/src/Videos';
import RequestManagement from '../../blocks/RequestManagement/src/RequestManagement';
import Customform from '../../blocks/customform/src/Customform';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import ActivityFeed from '../../blocks/ActivityFeed/src/ActivityFeed';
import ServiceSpecificSettingsAdmin from '../../blocks/ServiceSpecificSettingsAdmin/src/ServiceSpecificSettingsAdmin';
import Analytics from '../../blocks/analytics/src/Analytics';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import Invitefriends from '../../blocks/invitefriends/src/Invitefriends';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import StoreCredits from '../../blocks/StoreCredits/src/StoreCredits';;
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import UserGroups2 from '../../blocks/UserGroups2/src/UserGroups2';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import ProductDescription from '../../blocks/ProductDescription/src/ProductDescription';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import ContextTagging from '../../blocks/ContextTagging/src/ContextTagging';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import ProjectPortfolio from '../../blocks/ProjectPortfolio/src/ProjectPortfolio';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import TeamBuilder from '../../blocks/TeamBuilder/src/TeamBuilder';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole.web';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import VendorAccountRegistration from '../../blocks/email-account-registration/src/VendorAccountRegistration';
import VendorAccountLogin from '../../blocks/email-account-login/src/VendorAccountLogin.web';
import DashBoard from '../../blocks/dashboard/src/Dashboard.web';
import EmailNotifications2 from '../../blocks/EmailNotifications2/src/Emailnotifications2';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import InventoryManagement from '../../blocks/InventoryManagement/src/InventoryManagement.web';
import CustomisedOrderStatus from '../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus';
import InventoryManagement2 from '../../blocks/InventoryManagement2/src/InventoryManagement2';
import BlogpostsManagement from '../../blocks/BlogPostsManagement/src/BlogpostsManagement.web';
import Profile from '../../blocks/user-profile-basic/src/Profile.web';
import DrawerComponent from '../../components/src/NativeWebRouteWrapper/Drawer';
import InviteUser from '../../blocks/invitefriends/src/InviteUser.web';
import MySchool from '../../blocks/TeamBuilder/src/MySchool.web';
import CommonPageHeader from '../../components/src/NativeWebRouteWrapper/CommonPageHeader';
import SignUp from '../../blocks/email-account-login/src/SignUp.web';
import Login from '../../blocks/EmailNotifications2/src/Login.web';
import NewPasswordWeb from '../../blocks/forgot-password/src/NewPassword.web';
import Support from '../../blocks/RequestManagement/src/Support.web';
import LeadRoleScreen from '../../blocks/ActivityFeed/src/LeadRoleScreen';
import ForgotPasswordOne from '../../blocks/forgot-password/src/ForgotPassword.web';
import InviteTeachers from '../../blocks/RolesPermissions/src/InviteTeachers';
import TeacherOnboarding from '../../blocks/invitefriends/src/TeacherOnboarding.web';
import CallScheduler from '../../blocks/RequestManagement/src/CallScheduler.web';
import TrainingDetails from '../../blocks/categoriessubcategories/src/TrainingDetails.web';
import ProjectDetails from '../../blocks/categoriessubcategories/src/ProjectDetails.web';
import ProjectDetailsSub from '../../blocks/categoriessubcategories/src/ProjectDetailsSub.web';
import Trainings from '../../blocks/catalogue/src/Trainings.web';
import Requests from '../../blocks/CustomisedOrderStatus/src/Requests.web';
import AddCredits from '../../blocks/StoreCredits/src/AddCredits.web';
import ConfirmTraining from '../../blocks/catalogue/src/ConfirmTraining.web';
import PurchaseClassBox from '../../blocks/ContentManagement/src/PurchaseClassBox.web';
import Classboxes from '../../blocks/ContentManagement/src/Classboxes.web';
import StatusBar from '../../blocks/ContentManagement/src/StatusBar';
import Project from '../../blocks/ProjectTemplates/src/Project.web';
import CompleteProjectPage from '../../blocks/categoriessubcategories/src/CompleteProjectPage.web';
import CompleteProjectPageSub from '../../blocks/categoriessubcategories/src/CompleteProjectPageSub.web';
import StandardSelection from '../../blocks/UserGroups2/src/StandardSelection.web';
import RequestTraining from "../../blocks/catalogue/src/RequestTraining.web";
import CustomisableUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableUserProfiles.web"
import LeadSchool from '../../blocks/videos/src/LeadSchool.web';

const routeMap = {
  BlogPostsManagement: {
    component: BlogpostsManagement,
    path: '/BlogPostsManagement'
  },

  InventoryManagement2: {
    component: InventoryManagement2,
    path: '/InventoryManagement2'
  },

  ProjectReporting: {
    component: ProjectReporting,
    path: '/ProjectReporting'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: '/Ordermanagement'
  },
  OrderDetails: {
    component: OrderDetails,
    path: '/OrderDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  BreadcrumbNavigation: {
    component: BreadcrumbNavigation,
    path: '/BreadcrumbNavigation'
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders'
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem'
  },
  ProjectTemplates: {
    component: ProjectTemplates,
    path: '/ProjectTemplates'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  SplitViewInterface: {
    component: SplitViewInterface,
    path: '/SplitViewInterface'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  ActivityFeed: {
    component: ActivityFeed,
    path: '/ActivityFeed'
  },
  ServiceSpecificSettingsAdmin: {
    component: ServiceSpecificSettingsAdmin,
    path: '/ServiceSpecificSettingsAdmin'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  Invitefriends: {
    component: Invitefriends,
    path: '/Invitefriends'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  StoreCredits: {
    component: StoreCredits,
    path: '/StoreCredits'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  UserGroups2: {
    component: UserGroups2,
    path: '/UserGroups2'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  ProductDescription: {
    component: ProductDescription,
    path: '/ProductDescription'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  ContextTagging: {
    component: ContextTagging,
    path: '/ContextTagging'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  ProjectPortfolio: {
    component: ProjectPortfolio,
    path: '/ProjectPortfolio'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  TeamBuilder: {
    component: TeamBuilder,
    path: '/TeamBuilder'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  VendorAccountRegistration: {
    component: VendorAccountRegistration,
    path: '/VendorAccountRegistration'
  },
  VendorAccountLogin: {
    component: VendorAccountLogin,
    path: '/VendorAccountLogin'
  },
  Dashboard: {
    component: DashBoard,
    path: '/Dashboard'
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: '/EmailNotifications2'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  InventoryManagement: {
    component: InventoryManagement,
    path: '/InventoryManagement'
  },
  CustomisedOrderStatus: {
    component: CustomisedOrderStatus,
    path: '/CustomisedOrderStatus'
  },
  Trainings: {
    component: Trainings,
    path: '/Trainings'
  },

  Home: {
    component: Login,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  InviteUser: {
    component: InviteUser,
    path: '/InviteUser'
  },
  Profile: {
    component: Profile,
    path: '/Profile'
  },
  Staff: {
    component: MySchool,
    path: '/Staff'
  },
  SignUp: {
    component: SignUp,
    path: '/SignUp'
  },
  Login: {
    component: Login,
    path: '/Login'
  },
  NewPasswordWeb: {
    component: NewPasswordWeb,
    path: '/NewPasswordWeb'
  },
  Support: {
    component: Support,
    path: '/Support'
  },
  LeadRoleScreen: {
    component: LeadRoleScreen,
    path: '/LeadRoleScreen'
  },
  ForgotPasswordOne: {
    component: ForgotPasswordOne,
    path: '/ResetPssword'
  },
  InviteTeachers: {
    component: InviteTeachers,
    path: '/InviteTeachers'
  },
  TeacherOnboarding: {
    component: TeacherOnboarding,
    path: '/TeacherOnboarding'
  },
  ScheduleCall: {
    component: CallScheduler,
    path: '/ScheduleCall'
  },
  TrainingDetails: {
    component: TrainingDetails,
    path: '/TrainingDetails/:id/:credit'
  },
  Requests: {
    component: Requests,
    path: '/Requests'
  },
  AddCredits: {
    component: AddCredits,
    path: '/AddCredits'
  },
  ConfirmTraining: {
    component: ConfirmTraining,
    path: '/ConfirmTraining/:id/:title/:credit/:creator_tags'
  },
  PurchaseClassBox: {
    component: PurchaseClassBox,
    path: '/PurchaseClassBox/:ids'
  },
  Classboxes: {
    component: Classboxes,
    path: '/Classboxes'
  },
  StatusBar: {
    component: StatusBar,
    path: '/StatusBar/:id/:cost/:tab/:date'
  },
  ProjectDetails: {
    component: ProjectDetails,
    path: '/ProjectDetails/:id'
  },
  ProjectDetailsSub: {
    component: ProjectDetailsSub,
    path: '/ProjectDetailsSub/:id'
  },
  Project: {
    component: Project,
    path: '/Project'
  },
  CompletedProject: {
    component: CompleteProjectPage,
    path: '/CompletedProject/:credits/:title/:for'
  },
  CompletedProjectSub: {
    component: CompleteProjectPageSub,
    path: '/CompletedProjectSub'
  },
  StandardSelection: {
    component: StandardSelection,
    path: '/StandardSelection/:isLead'
  },
  RequestTraining: {
    component: RequestTraining,
    path: "/RequestTraining"
  },
  Home: {
    component: InventoryManagement,
    path: "/Home"
  },
  Orders: {
    component: InventoryManagement,
    path: "/Orders"
  },
  Products: {
    component: InventoryManagement,
    path: "/Products"
  },
  CustomisableUserProfiles:{
    component:CustomisableUserProfiles,
    path:"/CustomisableUserProfiles"
  },
  MySchool: {
    component: LeadSchool,
    path: '/MySchool'
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

let pagename = {
  myschool: {
    title: 'My School',
    icon: '✨'
  },
  staff: {
    title: 'Staff',
    icon: '🧑‍🏫'
  },
  profile: {
    title: 'My Profile',
    icon: '\uD83D\uDC64'
  },
  inviteuser: {
    title: 'Invite User',
    icon: '👤'
  },
  support: {
    title: 'Support',
    icon: '🙋'
  },
  teacherdashboard: {
    title: 'TeacherDashboard',
    icon: '🙋'
  },
  dashboard: {
    title: 'Dashboard',
    icon: ''
  },
  trainingdetails: {
    title: 'TrainingDetails',
    icon: ''
  },
  trainings: {
    title: 'Trainings',
    icon: `\u{1F3C5}`
  },
  requests: {
    title: 'Requests',
    icon: ''
  },
  addcredits: {
    title: 'Add Credits',
    icon: '✨'
  },
  classboxes: {
    title: 'Classboxes',
    path: ''
  },
  projectdetails: {
    title: 'ProjectDetails',
    path: ''
  },
  projectdetailsSub: {
    title: 'projectdetailsSub',
    path: ''
  },
  project: {
    title: 'Projects',
    icon: ''
  },
  home:{
    title:"Home",
    path:''
  },
  orders:{
    title:"Orders",
    path:''
  },
  products:{
    title:"Product",
    path:''
  },
  myschool:{
    title:"MySchool",
    path:''
  },
  customisableuserprofiles:{
    title:"CustomisableUserProfiles",
    path:''
  }
};

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    return (
      <View>
        {!!pagename[
          window.location.pathname
            .substring(0)
            .split('/')[1]
            .toLowerCase()
        ] ? (
          <DrawerComponent
            pathName={window.location.pathname
              .substring(0)
              .split('/')[1]
              .toLowerCase()}
          >
            {!showHeader(
              window.location.pathname
                .substring(0)
                .split('/')[1]
                .toLowerCase()
            ) && (
              <CommonPageHeader
                data={
                  pagename[
                    window.location.pathname
                      .substring(0)
                      .split('/')[1]
                      .toLowerCase()
                  ]
                }
              />
            )}
            {isUserloggedIn() ? (
              WebRoutesGenerator({ routeMap })
            ) : (
              <Redirect to="/Login" />
            )}
          </DrawerComponent>
        ) : (
          WebRoutesGenerator({ routeMap })
        )}
      </View>
    );
  }
}

export default App;